body {
  background-color: #2d3a44;
}

html {
  font-size: 62.5%;
}

h1 {
  font-size: 4.8rem;
  line-height: 5rem;
  margin-block-start: 0;
  margin-block-end: 0;
  font-weight: 600;
}

h2 {
  font-size: 3.6rem;
  line-height: 4.5rem;
  margin-block-start: 0;
  margin-block-end: 0;
  font-weight: 600;
}

h3 {
  font-size: 2.8rem;
  line-height: 3.5rem;
  margin-block-start: 0;
  margin-block-end: 0;
  font-weight: 600;
}

h4 {
  font-size: 1.8rem;
  line-height: 2.2rem;
  margin-block-start: 0;
  margin-block-end: 0;
  font-weight: 600;
}

p {
  font-size: 1.6rem;
  margin-block-start: 0;
  margin-block-end: 0;
}
